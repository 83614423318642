<template>
  <sheet-filters
    :local-loading="loading"
    :model-name="filterKey"
    :value="obReportFilters.value"
    no-vuex
    hide-reset-button
    persistent
    search-label="generate"
    @apply="apply"
    @reset="reset"
  >
    <template #default>
      <v-row>
        <v-col cols="12" lg="7" md="6">
          <v-row>
            <v-col cols="12" lg="6">
              <date-range v-model="dateRange" v-bind="dateOptions" />
            </v-col>

            <v-col cols="12" lg="3" md="6">
              <currency-select
                :value="obFilterData.currency"
                empty-option
                label="invoice.currency"
                only-used
                @change="onSelectCurrency"
              />
            </v-col>

            <v-col v-if="!hideCurrencyConvert" cols="12" lg="3" md="6">
              <currency-select
                v-model="sCurrencyConvert"
                :empty-option="
                  currencyConvertEmptyOption && !!obFilterData.currency
                "
                :ids="arCurrencyConvertIdList"
                autoassign
                company-only
                empty-label="invoice.currency"
                label="invoice.currency.convert"
                @change="onSelectCurrencyConvert"
              />
            </v-col>

            <v-col v-if="!hideMovementsType" cols="12" lg="3" md="6">
              <invoice-movement-type-select
                :value="obReportFilters.value.movementTypeCode"
                :movement-type-code="movementTypeCode"
                validate-company
                @change="onSelectMovementType"
              />
            </v-col>

            <v-col v-if="!hidePaymentType" cols="12" lg="4" md="6">
              <div
                class="text-body-2 mb-4"
                v-text="$t('report.payment.method')"
              />
              <v-radio-group v-model="byCashCredit" row>
                <v-radio :label="$t('all')" :value="0" />
                <v-radio :label="$t('cash')" :value="1" />
                <v-radio :label="$t('credit')" :value="2" />
              </v-radio-group>
            </v-col>

            <v-col v-if="!hideContentToggle" cols="12" lg="4" md="6">
              <div class="text-body-2 mb-4" v-text="$t('report.type')" />
              <v-radio-group v-model="showContent" row>
                <v-radio :label="$t('report.resumed')" :value="0" />
                <v-radio :label="$t('report.detailed')" :value="1" />
              </v-radio-group>
            </v-col>
          </v-row>
          <slot :filters="obReportFilters.value" />
        </v-col>

        <v-col class="d-none d-md-flex justify-center" cols="1">
          <v-divider vertical />
        </v-col>

        <v-col cols="12" lg="4" md="6">
          <company-preview />
          <branch-select
            v-model="obReportFilters.value.branch_id"
            class="mt-4"
            label="branch"
            multiple
            tag="div"
          />
        </v-col>
      </v-row>

      <v-row align="end">
        <v-col cols="12">
          <v-select
            v-model="arSelectedHeaders"
            :items="headers"
            :label="$t('report.header.columns')"
            :menu-props="{ offsetY: true }"
            chips
            deletable-chips
            dense
            hide-details="auto"
            multiple
            outlined
            @change="onSelectColumns"
          />
        </v-col>
      </v-row>
    </template>

    <template #bottom>
      <v-checkbox
        v-model="obReportFilters.value.generate_xls"
        :value="true"
        :label="$t('report.download.xls')"
      />
    </template>
  </sheet-filters>
</template>

<script lang="ts">
import {
  Component,
  Mixins,
  Prop,
  PropSync,
  Watch,
} from "vue-property-decorator";
import DataTableMixin, { DataTableHeader } from "@/mixins/DataTableMixin";
import { filter, has, isArray, isEmpty, map } from "lodash";
import dayjs from "dayjs";
import { InvoiceMovementTypeData, TaxType } from "@planetadeleste/vue-mc-gw";
import CompanyPreview from "@/modules/companies/components/CompanyPreview.vue";
import BranchSelect from "@/modules/companies/components/BranchSelect.vue";
import InvoiceMovementTypeSelect from "@/modules/invoices/components/InvoiceMovementTypeSelect.vue";
import CurrencySelect from "@/modules/currencies/components/CurrencySelect.vue";
import DateRange from "@/components/form/fields/DateRange.vue";
import SheetFilters from "@/components/form/SheetFilters.vue";
import { AppModule } from "@/store/app";
import {
  Currency,
  CurrencyCollection,
  CurrencyData,
} from "@planetadeleste/vue-mc-shopaholic";
import { eqJson } from "@/plugins/helpers";
import ReportFilterMixin from "@/modules/reports/mixins/ReportFilterMixin";

export interface FilterData {
  any_currency?: number;
  branch_id?: number[];
  cachekey?: string;
  cash?: number;
  created_at?: string[];
  created_at_to?: string;
  credit?: number;
  currency?: number;
  currency_convert?: number;
  customer_type?: "customer" | "provider";
  date_type?: "sign_at" | "due_date";
  doc_id?: string;
  due_date_to?: string;
  generate_xls?: boolean;
  invoiceMovementType?: number | number[];
  invoice_customer?: number;
  movementTypeCode?: number | number[];
  no_check_skip_balance?: number;
  show_content?: number;
  sign_at?: string[];
  signed?: number;
  taxtype_id?: number[];
  unpaid?: number;
}

@Component({
  components: {
    SheetFilters,
    DateRange,
    CurrencySelect,
    InvoiceMovementTypeSelect,
    BranchSelect,
    CompanyPreview,
  },
})
export default class ReportFilters extends Mixins(
  DataTableMixin,
  ReportFilterMixin
) {
  @Prop(Boolean) readonly loading!: boolean;
  @Prop(Boolean) readonly hidePaymentType!: boolean;
  @Prop(Boolean) readonly hideCurrencyConvert!: boolean;
  @Prop(Boolean) readonly hideContentToggle!: boolean;
  @Prop(Boolean) readonly hideContent!: boolean;
  @Prop(Boolean) readonly hideMovementsType!: boolean;
  @Prop(Boolean) readonly currencyConvertEmptyOption!: boolean;
  @Prop(String) readonly filterKey!: string;
  @Prop({ type: Array, default: () => [1] })
  readonly movementTypeCode!: number[];

  @Prop({ type: Object, default: () => ({}) }) readonly dateOptions!: Record<
    string,
    any
  >;

  @PropSync("selectedColumns", { type: Array, default: () => [] })
  arSelectedHeaders!: DataTableHeader["value"][];

  // obFilterData: Partial<FilterData> = { currency: 0, currency_convert: 0 };
  sCurrencyConvert: number = 0;
  obTaxTypes: TaxType[] = [];

  get dateRange(): string[] {
    return isArray(this.obFilterData.created_at) &&
      !isEmpty(this.obFilterData.created_at)
      ? this.obFilterData.created_at
      : [];
  }

  set dateRange(arValue: string[]) {
    this.setFilterData("created_at", arValue);
  }

  get byCashCredit() {
    if (!has(this.obFilterData, "cash") && !has(this.obFilterData, "credit")) {
      return 0;
    }

    return this.obFilterData.cash ? 1 : 2;
  }

  set byCashCredit(sValue: number) {
    this.setFilter("cash", 0);
    this.setFilter("credit", 0);

    if (sValue === 1) {
      this.unsetFilter("credit");
      this.setFilter("cash", 1);
    } else if (sValue === 2) {
      this.unsetFilter("cash");
      this.setFilter("credit", 1);
    } else {
      this.unsetFilter("credit");
      this.unsetFilter("cash");
    }

    this.change();
  }

  get showContent() {
    return this.obFilterData.show_content || 1;
  }

  set showContent(sValue: number) {
    this.setFilterData("show_content", sValue);
  }

  get obCurrencyCollection() {
    return AppModule.currencies;
  }

  get sCurrencyBase() {
    if (!this.obFilterData.currency) {
      return null;
    }

    const obCurrency = this.obCurrencyCollection.find({
      id: this.obFilterData.currency,
    });

    return obCurrency ? obCurrency.code : null;
  }

  get iCurrencyLocalId() {
    return this.obCurrencyCollection
      .filter<CurrencyCollection>(
        (obCurrency: CurrencyData) => obCurrency.code === "UYU"
      )
      .first()?.id;
  }

  get arCurrencyConvertIdList(): number[] {
    if (!this.sCurrencyBase || this.sCurrencyBase !== "UYU") {
      return this.obCurrencyCollection
        .filter((obCurrency: CurrencyData) =>
          ["UYU", "USD"].includes(obCurrency.code)
        )
        .map("id");
    }

    return this.obCurrencyCollection.map("id");
  }

  @Watch("obFilterData", { deep: true, immediate: true })
  onChangeFilters(
    obData: Partial<FilterData>,
    obOldData?: Partial<FilterData>
  ) {
    if (obOldData && eqJson(obData, obOldData)) {
      return;
    }

    this.change();
  }

  mounted() {
    this.setDTHeaders(this.obReportHeaders.value);
    this.onSelectColumns(this.arSelectedHeaders);
    this.reset();
  }

  apply() {
    this.$emit("apply", this.obFilterData);
  }

  change() {
    this.$emit("change", this.obFilterData);
  }

  reset() {
    const arCreatedAt: string[] = [
      dayjs().startOf("month").format("YYYY-MM-DD"),
      dayjs().format("YYYY-MM-DD"),
    ];
    this.replaceFilter({
      created_at: arCreatedAt,
      show_content: this.hideContent ? 0 : 1,
      currency: 0,
      currency_convert: 0,
    });
    this.$emit("reset");
    this.sCurrencyConvert = this.obFilterData.currency_convert ?? 0;
  }

  setFilterData(sKey: keyof FilterData, sValue: any) {
    this.setFilter(sKey, sValue, this.change);
  }

  onUpdateTaxTypes(arItems: TaxType[]) {
    this.obTaxTypes = arItems;

    if (arItems.length && !this.obFilterData.taxtype_id) {
      const arTaxTypeListId = map(arItems, "id");
      this.setFilterData("taxtype_id", arTaxTypeListId);
    }
  }

  onSelectColumns(arValue: DataTableHeader["value"][]) {
    const arSelectedColumns = filter(this.arHeaders, (obItem) =>
      arValue.includes(obItem.value)
    );

    this.$emit("columns", arSelectedColumns);
  }

  onSelectCurrency(obCurrencyModel?: Currency) {
    this.setFilterData("currency", obCurrencyModel?.id);
  }

  onSelectMovementType(obData: InvoiceMovementTypeData) {
    this.setFilterData("movementTypeCode", obData.code);
  }

  onSelectCurrencyConvert(obCurrencyModel?: Currency) {
    this.setFilterData("currency_convert", obCurrencyModel?.id);
  }
}
</script>
